<template>
  <div style="position: relative">
    <div class="pxp-cta-3 tw-py-24" :style="`background: var(--${vars.backgroundColor});`">
      <div class="container">
        <div
          class="align-items-center tw-flex tw-flex-col tw-justify-center tw-gap-7 lg:tw-grid lg:tw-grid-cols-2 lg:tw-gap-20 2xl:tw-gap-40"
          :class="{ 'tw-flex-row-reverse': vars.reverseNumber === 1 }"
        >
          <div
            v-if="vars.contentIsVideoNumber === 0"
            style="z-index: 2"
            :class="isReversed ? 'md:tw-order-2' : 'tw-order-1'"
            class="tw-w-full"
          >
            <library-image v-if="vars.contentImage" :src="vars.contentImage"></library-image>
          </div>
          <div v-else :class="isReversed ? 'md:tw-order-2' : 'tw-order-1'" class="tw-w-full" style="z-index: 2">
            <div class="tw-relative">
              <library-image v-if="vars.contentImage" :src="vars.contentImage"></library-image>
              <iframe
                v-else-if="vars.contentVideo"
                class="video tw-h-[256px] tw-w-full lg:tw-h-[450px]"
                :src="vars.contentVideo"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
              <div
                v-if="vars.isPopupBool"
                class="tw-absolute tw-left-1/2 tw-top-1/2 tw-z-10 tw-w-full -tw-translate-x-1/2 -tw-translate-y-1/2"
              >
                <button
                  class="pxp-video-section-trigger tw-absolute tw-left-1/2 tw-top-1/2 -tw-translate-x-1/2 -tw-translate-y-1/2"
                  @click="() => togglePopup()"
                >
                  <span class="fa fa-play"></span>
                </button>
              </div>
            </div>
          </div>

          <!-- <div class="col-lg-1"></div> -->
          <div style="z-index: 2" :class="isReversed ? 'md:tw-order-1' : 'tw-order-2'">
            <h2 class="font-plus-jakarta-semibold tw-text-3xl lg:tw-text-4xl">
              {{ vars.titleText }}
            </h2>
            <library-text
              v-if="vars.accordionTopTextText"
              :text="vars.accordionTopTextText"
              class="tw-mt-3"
            ></library-text>
            <library-content-accordion v-if="variant === variants.V2Accordion" :elements="accordionElements" />
            <template v-else-if="variant === variants.v1">
              <p v-for="(item, index) in descriptions" :key="`description-${index}`" class="font-arial mt-3 mt-lg-4">
                {{ item.description }}
              </p>
            </template>
            <nuxt-link
              v-if="vars.ctaUrl && vars.ctaText"
              :to="vars.ctaUrl"
              class="pxp-primary-cta text-uppercase mt-3 mt-md-5 pxp-animate"
              :external="true"
            >
              {{ ctaText }}
            </nuxt-link>
          </div>
        </div>
      </div>
    </div>
    <NuxtImg
      v-if="isPlayBox"
      class="tw-absolute tw-bottom-0 tw-left-0 tw-z-0 tw-h-[180px] tw-w-[310px] lg:tw-h-[400px] lg:tw-w-[646px]"
      src="https://files.neilking.neuronwebsites.co.uk/play-mask%20(1)-t5mctdpu6.png"
      title="play box"
      alt="play box object"
    />
    <div
      v-if="isPlayBox"
      class="tw-absolute tw-bottom-0 tw-right-0 tw-z-0 tw-w-0 lg:tw-h-[200px] lg:tw-w-[200px]"
      style="background-color: var(--purple)"
    ></div>

    <div
      v-if="isLeftBox"
      class="tw-absolute tw-left-0 tw-top-0 tw-z-0 tw-h-[368px] tw-w-full lg:tw-bottom-0 lg:tw-top-[unset] lg:tw-h-[438px] lg:tw-w-[500px]"
      style="background-color: var(--purple)"
    />
    <div
      v-if="isRightBox"
      class="tw-absolute tw-right-0 tw-top-0 tw-z-0 tw-h-[220px] tw-w-full lg:tw-h-[368px] lg:tw-w-[528px]"
      style="background-color: var(--purple)"
    />

    <library-popups-video-popup v-model:is-visible="showPopup" :video-url="vars.contentVideo" />
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'ContentBox',

  mixins: [ComponentMixin],

  data() {
    return {
      showPopup: false,

      variants: {
        v1: 'v1',
        V2Accordion: 'V2Accordion',
      },
    };
  },

  computed: {
    descriptions() {
      return this.groupedVariables.descriptions;
    },

    ctaText() {
      return this.vars.ctaText || 'Learn More';
    },

    isPlayBox() {
      return this.vars.backgroundObjectsText === 'play-box';
    },

    isLeftBox() {
      return this.vars.backgroundObjectsText === 'left-bottom-box';
    },
    isRightBox() {
      return this.vars.backgroundObjectsText === 'right-top-box';
    },

    isReversed() {
      return this.vars.reverseNumber === 1;
    },

    accordionElements() {
      return this.groupedVariables.accordion;
    },
  },

  methods: {
    togglePopup() {
      this.showPopup = !this.showPopup;
    },

    openVideo() {
      this.togglePopup();
      const videoCoverDiv = this.$refs.videoCover as HTMLDivElement;
      const iframeSrc = videoCoverDiv.querySelector('iframe')?.getAttribute('src');

      const iframeVideo = this.$refs.iframeVideo as HTMLIFrameElement;

      if (iframeSrc && iframeVideo) {
        iframeVideo.setAttribute('src', iframeSrc);
      }
    },
  },
});
</script>

<style scoped>
.inline-iframe-container {
  width: 100%;
  cursor: pointer;
}

.inline-iframe-container iframe {
  height: 388px;
}

.video-cover {
  width: 100%;
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.video-cover::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.popup {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.video-popup {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
}

.video-popup video,
.video-popup iframe {
  position: relative;
  max-width: 900px;
  outline: none;
}

.video-popup .close {
  top: 30px;
  right: 30px;
  max-width: 35px;
  cursor: pointer;
  filter: invert(1);
}

.video-popup {
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
}

@media (max-width: 991px) {
  .video-popup video,
  .video-popup iframe {
    max-width: 90%;
  }
}
</style>
