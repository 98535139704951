<template>
  <div class="container">
    <div class="row g-5 d-flex justify-content-between tw-mt-4 lg:tw-mt-12">
      <div :class="[vars.guideImage ? 'col-lg-7' : 'col-lg-7 mx-auto flex items-center justify-center lg:w-[80%]']">
        <lazy-library-title v-if="vars.titleText" tag="h3" :text="vars.titleText"></lazy-library-title>
        <lazy-library-form
          v-if="form"
          :form="form"
          :component="component"
          :is-form-name-visible="vars.isFormNameVisible || false"
        />
      </div>
      <div v-if="vars.guideImage" class="col-lg-5">
        <div class="image-wrapper blog h-100">
          <img alt="" loading="lazy" :src="String(vars.guideImage)" class="cover-image" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'GuideForm',

  mixins: [ComponentMixin],

  data() {
    return {
      form: null,
    };
  },
  created() {
    try {
      const form = getVariable(this.component, 'guide-form') as string;
      const parsedForm = JSON.parse(form);
      if (form && Object.keys(parsedForm).length) {
        this.form = parsedForm;
      }
    } catch (e) {}
  },
});
</script>

<script setup lang="ts"></script>

<style scoped>
@media screen and (max-width: 479px) {
  .image-wrapper.blog {
    width: unset;
  }
}
</style>
