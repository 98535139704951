<template>
  <div class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-py-12 lg:tw-py-28" style="position: relative">
    <library-title
      style="z-index: 2"
      :text="vars.titleText"
      tag="h3"
      class="tw-mb-10 tw-px-3 lg:tw-px-0"
    ></library-title>
    <NuxtImg
      :src="vars.backgroundImage"
      class="tw-full tw-z-[2] tw-h-[418px] tw-px-3 lg:tw-h-[768px] lg:tw-px-0"
      :title="vars.titleText"
    />

    <div
      v-if="areBoxesVisible"
      class="tw-absolute tw-bottom-0 tw-left-0 tw-z-0 tw-h-[448px] tw-w-0 lg:tw-w-[500px]"
      style="background-color: var(--purple)"
    />
    <div
      v-if="areBoxesVisible"
      class="tw-absolute tw-right-0 tw-top-0 tw-z-0 tw-h-[448px] tw-w-0 lg:tw-w-[500px]"
      style="background-color: var(--purple)"
    />
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'Theme3FeaturedImage',

  mixins: [ComponentMixin],

  computed: {
    areBoxesVisible() {
      return this.vars.backgroundObjectsText === 'left-top-right-bottom';
    },
  },
});
</script>
